:root {
  --main-color-dark: #333;
  --secondary-color-dark: #fff;
  --border-color-dark: #555;
  --background-color-rgb-dark: rgba(0, 0, 0, 0.2);
  --secondary-background-color-rgb-dark: rgba(255, 255, 255, 0.3);
  --thirty-background-color-rgb-dark: rgba(255, 255, 255, 1);
  --navigation-background-color-dark: rgba(0, 0, 0, 1);
  --bg-lines-color-dark: rgba(255, 255, 255, 0.1);
  --paragraph-color-dark: rgba(255, 255, 255, 0.7);
  --links-opacity-color-dark: rgba(255, 255, 255, 0.9);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  background-color: var(--main-color-dark);
  color: var(--secondary-color-dark);
  font-family: "Montserrat", sans-serif;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-loader {
  position: absolute;
  top: 50%;
}