.wrapper {
  padding-left: 300px;
  position: relative;
  min-height: 100vh;
  z-index: 1;
}

.bgLines {
  left: 0;
  width: 100%;
  z-index: -1;
}

.bgLines,
.bgLines span {
  position: absolute;
  top: 0;
  height: 100%;
  min-height: 100vh;
}

.bgLines span {
  background: var(--bg-lines-color-dark);
  display: inline-block;
  left: 0;
  width: 1px;
}

.bgLines span:first-child {
  left: 20%;
}

.bgLines span:nth-child(2) {
  left: 40%;
}

.bgLines span:nth-child(3) {
  left: 60%;
}

.bgLines span:nth-child(4) {
  left: 80%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .bgLines {
    left: 260px;
    width: calc(100% - 260px);
  }
}

@media only screen and (max-width: 767px) {
  .bgLines {
    left: 0;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    padding-left: 0;
  }
}
