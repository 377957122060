.mainNav {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: var(--navigation-background-color-dark);
  top: 0;
  left: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 100;
}

.menuActive {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  z-index: 99;
}

.nav {
  display: flex;
  flex-direction: column;
  height: 100vh;
  transition: all 0.6s ease-out;
}

.navActive {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--navigation-background-color-dark);
  z-index: 99;
  transform: translate(0);
  transition: all 0.6s ease-out;
}

.navImage {
  display: flex;
  flex-grow: 3;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-right: 1px var(--border-color-dark) solid;
  border-bottom: 1px var(--border-color-dark) solid;
}

.navLogo {
  max-width: 150px;
  max-height: 150px;
  position: relative;
}

.logo {
  width: 100%;
  height: 100%;
  border: 3px solid var(--border-color-dark);
  border-radius: 50%;
}

.navMenu {
  display: flex;
  flex-grow: 15;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-right: 1px solid var(--border-color-dark);
  border-bottom: 1px solid var(--border-color-dark);
}

.navLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(
    to right,
    var(--border-color-dark),
    var(--border-color-dark) 50%,
    var(--secondary-color-dark) 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  position: relative;
  background-clip: content-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 5px 25px;
  margin: 0.5rem;
}

.navLink::before {
  content: "";
  background: var(--border-color-dark);
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.navLink:hover {
  background-position: 0;
}

.navLink:active::before,
.navLink:focus {
  background-position: 0;
}

.navLink:hover::before {
  width: 100%;
}

.navLink:active::before,
.navLink:focus::before {
  width: 100%;
}

.navCopyrights {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-right: 1px solid var(--border-color-dark);
}

.title {
  font-size: 0.8rem;
}

.burgerMenu {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  margin: 1rem;
  padding: 5px;
  width: 50px;
  height: 40px;
  z-index: 100;
}

.burgerMenu span:nth-child(1) {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.burgerMenu span:nth-child(2) {
  position: absolute;
  top: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.burgerMenu span:nth-child(3) {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.toggler span:nth-child(1) {
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
}

.toggler span:nth-child(2) {
  visibility: hidden;
}

.toggler span:nth-child(3) {
  top: 18px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1024px) {
  .mainNav {
    background-color: inherit;
  }

  .nav {
    transform: translate(-300px);
  }

  .burgerMenu {
    display: flex;
  }
}

/* @keyframes burgerAnimation {
  from {
    transform: translate(-300px);
  }
  to {
    transform: translate(0);
  }
} */
